/* eslint-disable consistent-return */
/* eslint-disable no-case-declarations */
import React, { useEffect, useState } from 'react';
import { CrossShape } from '../components/PreWriting/CrossShape';
import { useAudioContext } from '../context/AudioContext';
import plink from '../assets/audio/activitySuccess/plink.mp3';
import errorVO from '../assets/audio/preWriting/PW2/VO103V2.m4a';
import plusSignIntroVO from '../assets/audio/preWriting/PW2/VO110.m4a';
import nowItsYourTurnVO from '../assets/audio/preWriting/PW2/VO111.m4a';
import youMadeAPlusSignVO from '../assets/audio/preWriting/PW2/VO112.m4a';
import starsVO from '../assets/audio/preWriting/PW2/VO113.m4a';
import makeAnotherPlusSignVO from '../assets/audio/preWriting/PW2/VO114.m4a';
import lookAtTheStarsVO from '../assets/audio/preWriting/PW2/VO115.m4a';

type CrossShapeActivityProps = {
  onFinish: () => void,
}

export function CrossShapeActivity({ onFinish }: CrossShapeActivityProps) {
  const [iterationCount, setIterationCount] = useState(1);
  const [isStrokeCorrect, setIsStrokeCorrect] = useState(false);
  const [isPlayingMedia, setIsPlayingMedia] = useState<boolean>(true);
  const [displayNextIteration, setDisplayNextIteration] = useState<boolean>(true);
  const [firstIterationSuccess, setFirstIterationSuccess] = useState<boolean>(false);
  const [secondIterationSuccess, setSecondIterationSuccess] = useState<boolean>(false);
  const [thirdIterationSuccess, setThirdIterationSuccess] = useState<boolean>(false);
  const audioContext = useAudioContext();

  const correctStroke = (value: boolean) => {
    setIsStrokeCorrect(value);
  };

  useEffect(() => {
    switch (iterationCount) {
      case 1:
        setDisplayNextIteration(true);
        setIsPlayingMedia(true);
        const playNextAudio = () => {
          audioContext?.handlePlay({ src: nowItsYourTurnVO, onEnd: () => setIsPlayingMedia(false) });
        };
        const playPlusSignIntroVO = () => {
          audioContext?.handlePlay({
            src: plusSignIntroVO,
            onEnd: () => playNextAudio(),
          });
        };
        playPlusSignIntroVO();
        break;
      case 2:
        setIsPlayingMedia(true);
        const playThirdAudio2 = () => {
          audioContext?.handlePlay({
            src: makeAnotherPlusSignVO,
            onEnd: () => {
              setIsPlayingMedia(false);
              setDisplayNextIteration(true);
            },
          });
        };
        const playSecondAudio2 = () => {
          audioContext?.handlePlay({
            src: starsVO,
            onEnd: () => playThirdAudio2(),
          });
        };
        const playFirstVO2 = () => {
          audioContext?.handlePlay({
            src: youMadeAPlusSignVO,
            onEnd: () => playSecondAudio2(),
          });
        };
        const playFeedbackVO2 = () => {
          audioContext?.handlePlay({
            src: isStrokeCorrect ? plink : errorVO,
            onEnd: () => (isStrokeCorrect ? playFirstVO2() : playThirdAudio2()),
          });
        };
        playFeedbackVO2();
        break;
      case 3:
        setIsPlayingMedia(true);
        const playThirdAudio3 = () => {
          audioContext?.handlePlay({
            src: makeAnotherPlusSignVO,
            onEnd: () => {
              setIsPlayingMedia(false);
              setDisplayNextIteration(true);
            },
          });
        };
        const playSecondAudio3 = () => {
          audioContext?.handlePlay({
            src: starsVO,
            onEnd: () => playThirdAudio3(),
          });
        };
        const playFirstVO3 = () => {
          audioContext?.handlePlay({
            src: youMadeAPlusSignVO,
            onEnd: () => playSecondAudio3(),
          });
        };
        const playFeedbackVO3 = () => {
          audioContext?.handlePlay({
            src: isStrokeCorrect ? plink : errorVO,
            onEnd: () => (isStrokeCorrect ? playFirstVO3() : playThirdAudio3()),
          });
        };
        playFeedbackVO3();
        break;
      case 4:
        const playFinalVO = () => {
          audioContext?.handlePlay({ src: lookAtTheStarsVO });
        };
        const playFeedbackVO4 = () => {
          audioContext?.handlePlay({
            src: isStrokeCorrect ? plink : errorVO,
            onEnd: () => isStrokeCorrect && playFinalVO(),
          });
        };
        playFeedbackVO4();
        return onFinish();
      default:
        return onFinish();
    }
  }, [iterationCount]);

  const handleFinishIteration = () => {
    setDisplayNextIteration(false);
    setIterationCount(iterationCount + 1);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', marginLeft: -44 }}>
      {iterationCount >= 1
       && (
       <CrossShape
         iteration={1}
         canWrite={!isPlayingMedia}
         shouldAutoplayDemo
         correctStroke={(value) => {
           setFirstIterationSuccess(value);
           correctStroke(value);
         }}
         completed={firstIterationSuccess}
         onFinish={handleFinishIteration}
       />
       )}
      {((displayNextIteration && iterationCount === 2) || iterationCount > 2)
        && (
        <CrossShape
          iteration={2}
          canWrite={!isPlayingMedia}
          correctStroke={(value) => {
            setSecondIterationSuccess(value);
            correctStroke(value);
          }}
          completed={secondIterationSuccess}
          onFinish={handleFinishIteration}
        />
        )}
      {((displayNextIteration && iterationCount === 3) || iterationCount > 3)
         && (
         <CrossShape
           iteration={3}
           canWrite={!isPlayingMedia}
           correctStroke={(value) => {
             setThirdIterationSuccess(value);
             correctStroke(value);
           }}
           completed={thirdIterationSuccess}
           onFinish={handleFinishIteration}
         />
         )}
    </div>
  );
}
