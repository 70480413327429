/* eslint-disable react/require-default-props */
/* eslint-disable react/no-array-index-key */
import React, { useState, useRef } from 'react';
import {
  Stage, Layer, Line,
} from 'react-konva';
import { ShapeType } from '../../data/types/preWriting/shapes';
import { ACCEPTABLE_ERROR_OFFSET } from '../../shared/constants';
import colors from '../../styles/colors';

interface PreWritingHandwritingAreaProps {
  canWrite: boolean,
  shape: ShapeType,
  width: number,
  height: number,
  isMultiStroke?: boolean,
  borderRadius?: string,
  isStrokeCorrect: (isValid: boolean) => void,
  onFinishDrawing: () => void,
}

const stageAlignment = {
  [ShapeType.VERTICAL_LINE]: { top: 0, left: -ACCEPTABLE_ERROR_OFFSET },
  [ShapeType.HORIZONTAL_LINE]: { top: -ACCEPTABLE_ERROR_OFFSET, left: 0 },
  [ShapeType.CROSS]: { top: 0, left: 0 },
  [ShapeType.CIRCLE]: { top: -ACCEPTABLE_ERROR_OFFSET / 2, left: -ACCEPTABLE_ERROR_OFFSET / 2 },
};

export default function PreWritingHandwritingArea({
  canWrite, shape, width, height, isMultiStroke, borderRadius, onFinishDrawing, isStrokeCorrect,
}: PreWritingHandwritingAreaProps) {
  const [lines, setLines] = useState<any>([]);

  // The null reference is needed to indicate that there's no previous state (initial stroke)
  const lastPointWasValid = useRef<boolean | null>(null);
  const isDrawing = useRef(false);

  // All invalid dots. We will use this for grading the stroke
  const [invalidDots, setInvalidDots] = useState<any>([]);

  const isValidPoint = (pointX: number, pointY: number) => {
    if (shape === ShapeType.VERTICAL_LINE) {
      return !(pointX < ACCEPTABLE_ERROR_OFFSET || pointX > width + ACCEPTABLE_ERROR_OFFSET);
    }
    if (shape === ShapeType.CIRCLE) {
      // The center of the circle is either height / 2 or width / 2, both are true
      const centerPoint = height / 2;
      const outerCircumferenceRadius = centerPoint - ACCEPTABLE_ERROR_OFFSET / 2;
      const innerCircumferenceRadius = outerCircumferenceRadius - 50;

      const distanceFromCenter = Math.sqrt(
        (centerPoint - pointX) * (centerPoint - pointX) + (centerPoint - pointY) * (centerPoint - pointY),
      );

      return (distanceFromCenter <= outerCircumferenceRadius && distanceFromCenter >= innerCircumferenceRadius);
    }
    return !(pointY < ACCEPTABLE_ERROR_OFFSET || pointY > height + ACCEPTABLE_ERROR_OFFSET);
  };

  const addPoint = (pointX: number, pointY: number, isValid: boolean) => {
    if (lastPointWasValid === null || lastPointWasValid.current !== isValid) {
      // Add new line segment to reflect the change of state (off-track <-> on-track)
      setLines([...lines, { points: [pointX, pointY], color: isValid ? colors.purple : colors.red }]);
    } else {
      // If the state is the same as before, add the point to the current line segment
      const newLines = [...lines];
      const lastLine = newLines[newLines.length - 1];
      lastLine.points = [...lastLine.points, pointX, pointY];
      setLines(newLines);
    }
    lastPointWasValid.current = isValid;
  };

  const handleTouchStart = (e: any) => {
    if (!canWrite) return;

    isDrawing.current = true;
    const pos = e.target.getStage().getPointerPosition();
    const isValid = isValidPoint(pos.x, pos.y);

    setInvalidDots([]);

    addPoint(pos.x, pos.y, isValid);
  };

  const handleTouchMove = (e: any) => {
    // no drawing - skipping
    if (!isDrawing.current || !canWrite) return;

    const stage = e.target.getStage();
    const { x, y } = stage.getPointerPosition();
    const isValid = isValidPoint(x, y);

    if (!isValid) {
      setInvalidDots([...invalidDots, [x, y]]);
    }

    addPoint(x, y, isValid);
  };

  const handleTouchEnd = () => {
    if (!canWrite) return;

    isDrawing.current = false;
    lastPointWasValid.current = null;

    if (!isMultiStroke) {
      setLines([]);
    }

    if (invalidDots.length > 0) {
      isStrokeCorrect(false);
    } else {
      isStrokeCorrect(true);
    }

    onFinishDrawing();
  };

  const setStageWidth = (): number => {
    switch (shape) {
      case ShapeType.VERTICAL_LINE:
        return width + ACCEPTABLE_ERROR_OFFSET * 2;
      case ShapeType.HORIZONTAL_LINE:
        return width;
      case ShapeType.CIRCLE:
        return width;
      default:
        return width;
    }
  };

  const setStageHeight = (): number => {
    switch (shape) {
      case ShapeType.VERTICAL_LINE:
        return height;
      case ShapeType.HORIZONTAL_LINE:
        return height + ACCEPTABLE_ERROR_OFFSET * 2;
      case ShapeType.CIRCLE:
        return height;
      default:
        return height;
    }
  };

  return (
    <div style={{
      position: 'absolute',
      left: stageAlignment[shape].left,
      top: stageAlignment[shape].top,
      borderRadius: borderRadius || 0,
      overflow: borderRadius && 'hidden',
    }}
    >
      <Stage
        width={setStageWidth()}
        height={setStageHeight()}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
      >
        <Layer>
          {lines.map((line: any, i: number) => (
            <Line
              key={i}
              points={line.points}
              stroke={line.color}
              strokeWidth={8}
              tension={0.5}
              lineCap="round"
              lineJoin="round"
              globalCompositeOperation="source-over"
            />
          ))}
        </Layer>
      </Stage>
    </div>
  );
}
