/* eslint-disable consistent-return */
/* eslint-disable no-case-declarations */
import React, { useEffect, useState } from 'react';
import {
  PreWritingHandwritingActivityLineShapeWrapper,
  PreWritingHandwritingActivityLineShape,
  PreWritingHandwritingActivityVerticalLineSuccess,
  PreWritingHandwritingActivityHorizontalLineSuccess,
}
  from '../../styles/components/PreWritingWrappers';
import { DrawingArea } from '../../styles/components/DrawingArea';
import PreWritingHandwritingArea from '../Konva/PreWritingHandwritingArea';
import { ShapeType } from '../../data/types/preWriting/shapes';
import LottiePlayer from '../LottiePlayer';
import verticalShootingStarLottie from '../../assets/lotties/PreWriting/PW2/shooting-star-down.json';
import horizontalShootingStarLottie from '../../assets/lotties/PreWriting/PW2/shooting-star-right.json';
import verticalLineLottie from '../../assets/lotties/PreWriting/PW2/verticalLine.json';
import horizontalLineLottie from '../../assets/lotties/PreWriting/PW2/horizontalLine.json';
import verticalLine from '../../assets/images/pre-writing/pw-2/PW2-line-vertical.svg';
import horizontalLine from '../../assets/images/pre-writing/pw-2/PW2-line-horizontal.svg';
import { useAudioContext } from '../../context/AudioContext';
import plink from '../../assets/audio/activitySuccess/plink.mp3';
import errorVO from '../../assets/audio/preWriting/PW2/VO103V2.m4a';
import straightLineIntroVO from '../../assets/audio/preWriting/PW2/VO93.mp3';
import nowItsYourTurn1VO from '../../assets/audio/preWriting/PW2/VO94.m4a';
import youMadeStraightLineDownVO from '../../assets/audio/preWriting/PW2/VO95.m4a';
import makeAnotherStraightLineDownVO from '../../assets/audio/preWriting/PW2/VO96.m4a';
import makeStraightLineAcrossVO from '../../assets/audio/preWriting/PW2/VO97.m4a';
import makeALineAcrossVO from '../../assets/audio/preWriting/PW2/VO98.m4a';
import nowItsYourTurn2VO from '../../assets/audio/preWriting/PW2/VO99.m4a';
import youMadeLineAcrossVO from '../../assets/audio/preWriting/PW2/VO100.m4a';
import shootingStarsVO from '../../assets/audio/preWriting/PW2/VO101.m4a';
import nextActivityVO from '../../assets/audio/preWriting/PW2/VO102.m4a';

type LineShapeProps = {
  onFinish: () => void,
}

export function LineShape({ onFinish }: LineShapeProps) {
  const [verticalAreaWidth, setVerticalAreaWidth] = useState<number>(0);
  const [verticalAreaheight, setVerticalAreaHeight] = useState<number>(0);
  const [horizontalAreaWidth, setHorizontalAreaWidth] = useState<number>(0);
  const [horizontalAreaHeight, setHorizontalAreaHeight] = useState<number>(0);
  const [isStrokeCorrect, setIsStrokeCorrect] = useState(false);
  const audioContext = useAudioContext();

  const [iterationCount, setIterationCount] = useState(1);
  const [canWrite, setCanWrite] = useState(false);
  const [isPlayingMedia, setIsPlayingMedia] = useState<boolean>(true);

  useEffect(() => {
    const verticalArea = document.getElementById('PreWritingVerticalLine');
    const horizontalArea = document.getElementById('PreWritingHorizontalLine');

    setVerticalAreaWidth(verticalArea?.clientWidth || 0);
    setVerticalAreaHeight(verticalArea?.clientHeight || 0);

    setHorizontalAreaWidth(horizontalArea?.clientWidth || 0);
    setHorizontalAreaHeight(horizontalArea?.clientHeight || 0);
  }, [iterationCount]);

  const handleFinishLine = () => {
    setIterationCount(iterationCount + 1);
  };

  const handleInstructionLottieFinish = () => {
    setCanWrite(true);
  };

  const handleIsStrokeCorrect = (isValid: boolean) => {
    setIsStrokeCorrect(isValid);
  };

  // When iterationCount reaches 8, it means the user has already gone through the vertical and horizontal lines twice
  // therefore they can now move on to the circle shape.
  useEffect(() => {
    switch (iterationCount) {
      case 1:
        setIsPlayingMedia(true);
        const playNextAudio = () => {
          audioContext?.handlePlay({
            src: nowItsYourTurn1VO,
            onEnd: () => setIsPlayingMedia(false),
          });
        };
        const playLineIntroVO = () => {
          audioContext?.handlePlay({
            src: straightLineIntroVO,
            onEnd: () => {
              playNextAudio();
            },
          });
        };
        playLineIntroVO();
        break;
      case 2:
        setIsPlayingMedia(true);
        const playSecondVO = () => {
          audioContext?.handlePlay({
            src: youMadeStraightLineDownVO,
            onEnd: () => {
              setIsPlayingMedia(false);
              setIterationCount(iterationCount + 1);
            },
          });
        };
        const playFirstVO = () => {
          audioContext?.handlePlay({
            src: isStrokeCorrect ? plink : errorVO,
            onEnd: () => {
              if (isStrokeCorrect) {
                playSecondVO();
              } else {
                setIsPlayingMedia(false);
                setIterationCount(iterationCount + 1);
              }
            },
          });
        };
        playFirstVO();
        break;
      case 3:
        setIsPlayingMedia(true);
        const playFirstVO2 = () => {
          audioContext?.handlePlay({
            src: makeAnotherStraightLineDownVO,
            onEnd: () => setIsPlayingMedia(false),
          });
        };
        playFirstVO2();
        break;
      case 4:
        const playVO = () => {
          audioContext?.handlePlay({ src: isStrokeCorrect ? plink : errorVO });
        };
        playVO();
        break;
      case 5:
        setIsPlayingMedia(true);
        const playSecondVO3 = () => {
          audioContext?.handlePlay({
            src: nowItsYourTurn2VO,
            onEnd: () => setIsPlayingMedia(false),
          });
        };
        const playFirstVO3 = () => {
          audioContext?.handlePlay({
            src: makeStraightLineAcrossVO,
            onEnd: () => {
              playSecondVO3();
            },
          });
        };
        playFirstVO3();
        break;
      case 6:
        setIsPlayingMedia(true);
        const playSecondVO4 = () => {
          audioContext?.handlePlay({
            src: youMadeLineAcrossVO,
            onEnd: () => {
              setIsPlayingMedia(false);
              setIterationCount(iterationCount + 1);
            },
          });
        };
        const playFirstVO4 = () => {
          audioContext?.handlePlay({
            src: isStrokeCorrect ? plink : errorVO,
            onEnd: () => {
              if (isStrokeCorrect) {
                playSecondVO4();
              } else {
                setIsPlayingMedia(false);
                setIterationCount(iterationCount + 1);
              }
            },
          });
        };
        playFirstVO4();
        break;
      case 7:
        setIsPlayingMedia(true);
        const playFirstVO5 = () => {
          // TODO: We are missing a "makeAnotherLineAcross" VO
          audioContext?.handlePlay({ src: makeALineAcrossVO, onEnd: () => setIsPlayingMedia(false) });
        };
        playFirstVO5();
        break;
      case 8:
        const playThirdVO6 = () => {
          audioContext?.handlePlay({ src: nextActivityVO });
        };
        const playSecondVO6 = () => {
          audioContext?.handlePlay({
            src: shootingStarsVO,
            onEnd: () => playThirdVO6(),
          });
        };
        const playFirstVO6 = () => {
          audioContext?.handlePlay({
            src: isStrokeCorrect ? plink : errorVO,
            onEnd: () => playSecondVO6(),
          });
        };
        playFirstVO6();
        return onFinish();
      default:
        return onFinish();
    }
  }, [iterationCount]);

  // Shapes get displayed dinamically according to the iterationCount number.
  // The final status (8) is where the vertical and the horizontal lines are being displayed.
  const handleShapesToDisplay = () => {
    switch (iterationCount) {
      case 1:
        return (
          <PreWritingHandwritingActivityLineShape url={verticalLine} iterationCount={iterationCount}>
            <div className="vertical-line-lottie">
              <LottiePlayer
                data={verticalLineLottie}
                shouldLoop={false}
                width="90%"
                shouldAutoplay
                handleComplete={() => handleInstructionLottieFinish()}
              />
            </div>
            <DrawingArea id="PreWritingVerticalLine" className="drawing-area-vertical-1">
              <PreWritingHandwritingArea
                shape={ShapeType.VERTICAL_LINE}
                width={verticalAreaWidth}
                height={verticalAreaheight}
                canWrite={!isPlayingMedia}
                onFinishDrawing={handleFinishLine}
                isStrokeCorrect={handleIsStrokeCorrect}
              />
            </DrawingArea>
          </PreWritingHandwritingActivityLineShape>
        );
      case 2:
        return (
          <PreWritingHandwritingActivityLineShape url={verticalLine} iterationCount={iterationCount}>
            <PreWritingHandwritingActivityVerticalLineSuccess>
              <div className="shooting-star-vertical-lottie-1">
                <LottiePlayer
                  data={verticalShootingStarLottie}
                  shouldLoop={false}
                  width="67%"
                  shouldAutoplay
                />
              </div>
            </PreWritingHandwritingActivityVerticalLineSuccess>
          </PreWritingHandwritingActivityLineShape>
        );
      case 3:
        return (
          <PreWritingHandwritingActivityLineShape url={verticalLine} iterationCount={iterationCount}>
            <PreWritingHandwritingActivityVerticalLineSuccess>
              <div className="shooting-star-vertical-lottie-1">
                <LottiePlayer
                  data={verticalShootingStarLottie}
                  segmentFrames={[112, 112]}
                  shouldLoop={false}
                  width="67%"
                  shouldAutoplay
                />
              </div>
            </PreWritingHandwritingActivityVerticalLineSuccess>
            <DrawingArea id="PreWritingVerticalLine" className="drawing-area-vertical-2">
              <PreWritingHandwritingArea
                shape={ShapeType.VERTICAL_LINE}
                width={verticalAreaWidth}
                height={verticalAreaheight}
                canWrite={canWrite && !isPlayingMedia}
                onFinishDrawing={handleFinishLine}
                isStrokeCorrect={handleIsStrokeCorrect}
              />
            </DrawingArea>
          </PreWritingHandwritingActivityLineShape>
        );
      case 4:
        return (
          <PreWritingHandwritingActivityLineShape url="" iterationCount={iterationCount}>
            <PreWritingHandwritingActivityVerticalLineSuccess>
              <div className="shooting-star-vertical-lottie-1">
                <LottiePlayer
                  data={verticalShootingStarLottie}
                  segmentFrames={[112, 112]}
                  shouldLoop={false}
                  width="67%"
                  shouldAutoplay
                />
              </div>
              <div className="shooting-star-vertical-lottie-2">
                <LottiePlayer
                  data={verticalShootingStarLottie}
                  shouldLoop={false}
                  width="67%"
                  shouldAutoplay
                  handleComplete={() => {
                    setIterationCount(iterationCount + 1);
                  }}
                />
              </div>
            </PreWritingHandwritingActivityVerticalLineSuccess>
          </PreWritingHandwritingActivityLineShape>
        );
      case 5:
        return (
          <>
            <div style={{ width: '30%', height: '100%', paddingLeft: '5%' }}>
              <PreWritingHandwritingActivityVerticalLineSuccess>
                <div className="shooting-star-vertical-lottie-1">
                  <LottiePlayer
                    data={verticalShootingStarLottie}
                    segmentFrames={[112, 112]}
                    shouldLoop={false}
                    width="67%"
                    shouldAutoplay
                  />
                </div>
                <div className="shooting-star-vertical-lottie-2">
                  <LottiePlayer
                    data={verticalShootingStarLottie}
                    segmentFrames={[112, 112]}
                    shouldLoop={false}
                    width="67%"
                    shouldAutoplay
                  />
                </div>
              </PreWritingHandwritingActivityVerticalLineSuccess>
            </div>
            <PreWritingHandwritingActivityLineShape url={horizontalLine} iterationCount={iterationCount}>
              <div className="horizontal-line-lottie">
                <LottiePlayer
                  data={horizontalLineLottie}
                  shouldLoop={false}
                  width="110%"
                  shouldAutoplay
                  handleComplete={() => handleInstructionLottieFinish()}
                />
              </div>
              <DrawingArea id="PreWritingHorizontalLine" className="drawing-area-horizontal">
                <PreWritingHandwritingArea
                  shape={ShapeType.HORIZONTAL_LINE}
                  width={horizontalAreaWidth}
                  height={horizontalAreaHeight}
                  canWrite={!isPlayingMedia}
                  isStrokeCorrect={handleIsStrokeCorrect}
                  onFinishDrawing={handleFinishLine}
                />
              </DrawingArea>
            </PreWritingHandwritingActivityLineShape>
          </>
        );
      case 6:
        return (
          <>
            <div style={{ width: '30%', height: '100%', paddingLeft: '5%' }}>
              <PreWritingHandwritingActivityVerticalLineSuccess>
                <div className="shooting-star-vertical-lottie-1">
                  <LottiePlayer
                    data={verticalShootingStarLottie}
                    segmentFrames={[112, 112]}
                    shouldLoop={false}
                    width="67%"
                    shouldAutoplay
                  />
                </div>
                <div className="shooting-star-vertical-lottie-2">
                  <LottiePlayer
                    data={verticalShootingStarLottie}
                    segmentFrames={[112, 112]}
                    shouldLoop={false}
                    width="67%"
                    shouldAutoplay
                  />
                </div>
              </PreWritingHandwritingActivityVerticalLineSuccess>
            </div>
            <PreWritingHandwritingActivityLineShape url={horizontalLine} iterationCount={iterationCount}>
              <PreWritingHandwritingActivityHorizontalLineSuccess>
                <div className="shooting-star-horizontal-lottie-1">
                  <LottiePlayer
                    data={horizontalShootingStarLottie}
                    shouldLoop={false}
                    width="90%"
                    shouldAutoplay
                  />
                </div>
              </PreWritingHandwritingActivityHorizontalLineSuccess>
            </PreWritingHandwritingActivityLineShape>
          </>
        );
      case 7:
        return (
          <>
            <div style={{ width: '30%', height: '100%', paddingLeft: '5%' }}>
              <PreWritingHandwritingActivityVerticalLineSuccess>
                <div className="shooting-star-vertical-lottie-1">
                  <LottiePlayer
                    data={verticalShootingStarLottie}
                    segmentFrames={[112, 112]}
                    shouldLoop={false}
                    width="67%"
                    shouldAutoplay
                  />
                </div>
                <div className="shooting-star-vertical-lottie-2">
                  <LottiePlayer
                    data={verticalShootingStarLottie}
                    segmentFrames={[112, 112]}
                    shouldLoop={false}
                    width="67%"
                    shouldAutoplay
                  />
                </div>
              </PreWritingHandwritingActivityVerticalLineSuccess>
            </div>
            <PreWritingHandwritingActivityLineShape url={horizontalLine} iterationCount={iterationCount}>
              <PreWritingHandwritingActivityHorizontalLineSuccess>
                <div className="shooting-star-horizontal-lottie-1">
                  <LottiePlayer
                    data={horizontalShootingStarLottie}
                    segmentFrames={[112, 112]}
                    shouldLoop={false}
                    width="90%"
                    shouldAutoplay
                  />
                </div>
              </PreWritingHandwritingActivityHorizontalLineSuccess>
              <DrawingArea id="PreWritingHorizontalLine" className="drawing-area-horizontal">
                <PreWritingHandwritingArea
                  shape={ShapeType.HORIZONTAL_LINE}
                  width={horizontalAreaWidth}
                  height={horizontalAreaHeight}
                  canWrite={!isPlayingMedia}
                  isStrokeCorrect={handleIsStrokeCorrect}
                  onFinishDrawing={handleFinishLine}
                />
              </DrawingArea>
            </PreWritingHandwritingActivityLineShape>
          </>
        );
      case 8:
        return (
          <>
            <div style={{ width: '30%', height: '100%', paddingLeft: '5%' }}>
              <PreWritingHandwritingActivityVerticalLineSuccess>
                <div className="shooting-star-vertical-lottie-1">
                  <LottiePlayer
                    data={verticalShootingStarLottie}
                    segmentFrames={[112, 112]}
                    shouldLoop={false}
                    width="67%"
                    shouldAutoplay
                  />
                </div>
                <div className="shooting-star-vertical-lottie-2">
                  <LottiePlayer
                    data={verticalShootingStarLottie}
                    segmentFrames={[112, 112]}
                    shouldLoop={false}
                    width="67%"
                    shouldAutoplay
                  />
                </div>
              </PreWritingHandwritingActivityVerticalLineSuccess>
            </div>
            <PreWritingHandwritingActivityLineShape url="" iterationCount={iterationCount}>
              <PreWritingHandwritingActivityHorizontalLineSuccess>
                <div className="shooting-star-horizontal-lottie-1">
                  <LottiePlayer
                    data={horizontalShootingStarLottie}
                    segmentFrames={[112, 112]}
                    shouldLoop={false}
                    width="90%"
                    shouldAutoplay
                  />
                </div>
                <div className="shooting-star-horizontal-lottie-2">
                  <LottiePlayer
                    data={horizontalShootingStarLottie}
                    shouldLoop={false}
                    width="90%"
                    shouldAutoplay
                  />
                </div>
              </PreWritingHandwritingActivityHorizontalLineSuccess>
            </PreWritingHandwritingActivityLineShape>
          </>
        );
      default:
        return 'An issue has been encountered. Please contact support.';
    }
  };

  return (
    <PreWritingHandwritingActivityLineShapeWrapper iterationCount={iterationCount}>
      { handleShapesToDisplay() }
    </PreWritingHandwritingActivityLineShapeWrapper>
  );
}
